import payme_icon from "../assets/images/payme-icon.svg";
import click_icon from "../assets/images/click-icon.svg";
import uzum_icon from "../assets/images/uzum-icon.svg";
import anor_icon from "../assets/images/anor-icon.svg";

export const images = {
    payme_icon,
    click_icon,
    uzum_icon,
    anor_icon
}